body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'SFpro',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E4E4E4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "SFpro";
  src: local("sf-pro-display-cufonfonts"),
   url("./styles/font/sf-pro-display-cufonfonts/SFPRODISPLAYREGULAR.OTF") format("Opentype");
  font-weight: regular;
  }
  